html,body {
  margin: 0;
  height: 100%;
  min-width: 360px;
}

#logo {
  max-height: 50px;
}

.nav-item {
  margin: 0 .5em;
}
.nav-link {
  color: rgb(50,50,50) !important;
}

.pt-4{
  padding-top: 0 !important;
}

#main-content {
  margin-top: 75px;
}

section {
  padding-top: 3em;
}

.content-section {
  padding-bottom: 3em;
}

.banner-section {
  padding: 1em 0;
}

.btn {
  margin-bottom: .5em;
}

#page-footer {
  margin-top: 3em;
  font-size: small;
  font-weight: lighter;
  padding: 5em;
}

.banner {
  padding: 5em 5em;
  margin: 0;
  width: 100%;
  min-height: 550px;
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  background-blend-mode: lighten;
  background-size: cover;
}

.banner .h1 {
  font-size: 4em;
}

.banner .h3 {
  font-size: 2em;
}

.text-orange {
  color: #F38B00 !important;
}

.my-text-shadow {
  text-shadow: 0px 0px 11px rgba(255,255,255,0.8);
}

.my-sub-header {
  font-size: 1.75em;
  font-weight: lighter;
}